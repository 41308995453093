import dayjs from 'dayjs';
import { sha512 } from 'js-sha512';
import storage from '@/lib/storage';

export const sha512Encrypt = (str) => sha512(str);

/**
 * 날짜 포맷 변경
 */
export const dateConvert = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);


/**
 * 문자열이 빈 문자열인지 체크하여 결과값을 리턴한다.
 */
export function isEmpty(str) {
  if (
    typeof str === 'undefined' ||
        str === null ||
        str === '' ||
        (str !== null && typeof str === 'object' && Object.keys(str).length) === 0
  ) {
    return true;
  }
  return false;
}

/**
 * 안드로이드 뒤로가기 처리 로직
 */
export function setBacKeyStorage(mode, page) {
  const getCallBackKeyAction = storage.getItem('backKeyStorage') || [];

  // callback log 추가
  if (mode === 'ADD') {
    // 마지막 요소가 현재 페이지와 동일하지 않을 경우만 저장
    if (getCallBackKeyAction[getCallBackKeyAction.length - 1] !== page) {
      getCallBackKeyAction.push(page);
      storage.setItem('backKeyStorage', getCallBackKeyAction);
    }
    // callback log 삭제
  } else if (mode !== 'ADD') {
    if (getCallBackKeyAction && getCallBackKeyAction.length > 0) {
      let idx;
      getCallBackKeyAction.forEach((value, index) => {
        // 현재 페이지와 동일 페이지의 마지막 요소(index) 구하기
        if (value === page) {
          idx = index;
        }
      });
      // 요소 삭제
      getCallBackKeyAction.splice(idx);
      storage.setItem('backKeyStorage', getCallBackKeyAction); // call back page 저장
    }
  }
}
