import dayjs from 'dayjs';
import MccToggles from '@/components/elements/toggles/index.vue';
import HeaderView from '@/components/layout/header/index.vue';
import { http } from '@/lib/axios';
import Bus from '@/lib/bus';
import storage from '@/lib/storage';
import { _url } from '@/lib/url';
import { dateConvert, isEmpty, setBacKeyStorage } from '@/lib/utils';
import store from '@/store';

export default {
  data() {
    return {};
  },
  components: {
    HeaderView,
    MccToggles,
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/getUser'];
    },
    certInfo() {
      return this.$store.getters['user/getCert'];
    },
    appCallBackUrl() {
      return this.$store.getters['user/getAppCallBackUrl'];
    },
    getCodes() {
      return this.$store.getters['comCode/getCodes'];
    },
  },
  methods: {
    dayjs,
    dateConvert,
    routerNamePush(name, query = {}) {
      this.$router.push({ name, query }).catch(() => {});
    },
    routerPathPush(path, query = {}) {
      this.$router.push({ path, query }).catch(() => {});
    },
    alertPopup(title, content) {
      return this.$swal.fire({
        title,
        text: content,
        html: content,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: '확인',
        focusConfirm: false,
        customClass: {
          popup: 'swal-alert-popup',
          image: 'swal-alert-image',
          title: 'swal-alert-title',
          htmlContainer: (title && content) ? 'swal-alert-html' : 'swal-alert-notitle-html',
          actions: 'swal-alert-actions',
          confirmButton: 'swal-alert-ok',
        },
      });
    },
    confirmPopup(title, content, popupInfo = {}) {
      const isButtonHalf = !isEmpty(popupInfo.isButtonHalf) ? popupInfo.isButtonHalf : false;
      const actionsAddClass = !isButtonHalf ? '' : 'swal-alert-actions-half';
      return this.$swal.fire({
        title,
        text: content,
        html: content,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: '확인',
        confirmButtonText: '취소',
        focusConfirm: false,
        customClass: {
          popup: 'swal-alert-popup',
          image: 'swal-alert-image',
          title: 'swal-alert-title',
          htmlContainer: (title && content) ? 'swal-alert-html' : 'swal-alert-notitle-html',
          actions: `swal-alert-actions ${actionsAddClass}`,
          confirmButton: 'swal-alert-cancel',
          cancelButton: 'swal-alert-ok',
        },
      });
    },
    appBackButtonBridge() {
      const getCallBackKeyAction = storage.getItem('backKeyStorage') || [];
      if (getCallBackKeyAction) {
        // 팝업창 닫기
        this.$swal.close();
        // callback log 마지막 요소를 제거한 후, 제거한 요소 정보
        const pageInfo = getCallBackKeyAction[getCallBackKeyAction.length - 1];
        const pageType = pageInfo.split('-')[0];

        // 앱종료
        if (pageType === 'Exit') {
          this.appWonderBridge();
        } else {
          const pageName = pageInfo.split('-')[1];

          // 슬라이드 팝업일 경우 닫기
          if (['Slide', 'Popup'].includes(pageType)) {
            Bus.$emit(`backKey-${pageInfo}`);
            return;
          }

          // 슬라이드 팝업일 경우 닫기
          if (['Cert'].includes(pageType)) {
            this.wooriSdkClose();
            return;
          }

          // 일반 화면일 경우 뒤로가기
          if (pageType === 'Back') {
            this.routerNamePush(pageName, { systemBack: true });
            setBacKeyStorage('DEL', pageInfo);
          }
        }
      } else {
        this.appWonderBridge();
      }
    },
    slidePanelOpen(path, props = {}) {
      return this.$showPanel({
        component: () =>
          import(`@/views${path}`),
        props,
        openOn: 'right',
      });
    },
    // select option 패널
    slidePanelBottomSheetOpen(props = {}) {
      return this.$showPanel({
        component: () =>
          import('@/components/modules/bottomSheet'),
        props,
        openOn: 'bottom',
        height: '432px',
        // height,
        cssClass: 'slideoutBottom',
      });
    },
    // slide 닫기
    slidePanelClose(result) {
      this.$emit('closePanel', result);
    },
    makeActionJon(actionCode, actionUrl, actionCallbackFunc = '', actionParam = null) {
      return JSON.stringify({
        ACTION_CODE: actionCode,
        ACTION_URL: actionUrl,
        ACTION_CALLBACK_FUNC: actionCallbackFunc,
        ACTION_PARAM: actionParam === null ? {} : actionParam,
      });
    },
    // 외부 브라우저
    appBrowserBridge(url) {
      const targetUrl = `iwebaction:${encodeURI(this.makeActionJon('ACT15006', url))}`;
      window.location.href = targetUrl;
    },
    // 앱종료
    appExitBridge() {
      const targetUrl = `iwebaction:${encodeURI(this.makeActionJon('ACT15007', 'ACTION_MAIN_HOME'))}`;
      window.location.href = targetUrl;
    },
    // 원더월렛
    appWonderBridge() {
      const targetUrl = 'iwebaction:{"ACTION_CODE": "ACT15000", "ACTION_URL": "NPCSA0295"}';
      window.location.href = targetUrl;
    },
    // 앱메뉴
    appMenuBridge() {
      const targetUrl = 'iwebaction:{"ACTION_CODE": "ACT15002"}';
      window.location.href = targetUrl;
    },
    // 원인증서 발급/재발급화면
    appAuthJoinBridge() {
      const targetUrl = 'iwebaction:{"ACTION_CODE": "ACT15000", "ACTION_URL": "NPCER0102"}';
      window.location.href = targetUrl;
    },
    // 납무고지
    appPayBridge() {
      const targetUrl = 'iwebaction:{"ACTION_CODE": "ACT15000", "ACTION_URL": "NPTAX0004"}';
      window.location.href = targetUrl;
    },
    // 앱 이동
    appMoveBridge() {
      const obj = {
        bookMarkWDR_ACNO: '', // 출금계좌번호
        bookMarkRCV_BKCD: '', // 은행코드 (269)
        bookMarkE2E_FLAG: '', // 확장E2E 코드값 (7)
        bookMarkE2E_RCV_ACNO: '', // 입금계좌번호
        bookMarkTRN_AM: '', // 이체금액
        bookMarkBankName: '', // 은행이름
        bookMarkExecDt: '', // 거래일자(과거이체실행일)
        bookMarkWDR_ACNO_TEXT: '', // 내통장표시내용
        bookMarkPBOKMNTNHIS_20: '', // 받는분 통장표시 내용
        APP_URL: 'ACTION_TRANSFER_ACT', // app 주소
      };
      const targetUrl = `iwebaction:${encodeURI(this.makeActionJon('ACT15008', '', '', obj))}`;
      window.location.href = targetUrl;
    },
    async wooriSignCi(appCallBackUrl) {
      await store.dispatch('user/setAppCallBackUrl', appCallBackUrl);

      // 로컬서버일 경우 예외 development
      if (process.env.VUE_APP_CA_USE === 'false') {
        this.appScrtMnsWPCSignCallback();
        return;
      }

      // if (this.userInfo.loginYn === 'N') {
      //   // 내 문서함 본인인증은 우리WON인증서로만 가능합니다.<br />우리WON인증서로 로그인해 주세요.
      //   this.alertPopup('', 'WON인증서 로그인 필요한 서비스입니다.').then(() => {
      //     this.appWonderBridge();
      //   });
      //   return;
      // }

      const _data = {
        SERIAL_NO: this.userInfo.serialNo,
        PLAIN_SIGN_DATA: dateConvert(new Date(), 'YYYYMMDDHHmmss'),
        CALLBACK_FUNC: 'appScrtMnsWPCSignCallback',
      };
      const targetUrl = `iwebkey6:${encodeURI(JSON.stringify(_data))}`;
      window.location = targetUrl;
    },
    // async
    async appScrtMnsWPCSignCallback(res) {
      // 로컬서버일 경우 예외 development
      if (process.env.VUE_APP_CA_USE === 'false') {
        this[this.appCallBackUrl](true);
        return;
      }

      const result = JSON.parse(res);
      if (result.RESULT_CODE === '0000') {
        // 앱에서 수신한 서명값 사설인증할 경우 사용
        const payload = {
          flag: 'eleNotice',
          itcsno: this.userInfo.loginId,
          eltsgnVal: result.SIGN_DATA,
        };
        http
          .post(_url.certVerifyResult, payload, {
            headers: {
              Authorization: process.env.VUE_APP_CA_TOKEN,
            },
          })
          .then(async () => {
            await Bus.$emit(`sign-${this.appCallBackUrl}`, true);
            await store.dispatch('user/setAppCallBackUrl', null);
            await store.dispatch('user/setCert', result.SIGN_DATA);
          })
          .catch(async () => {
            await Bus.$emit(`sign-${this.appCallBackUrl}`, false);
            await store.dispatch('user/setAppCallBackUrl', null);
            await store.dispatch('user/setCert', null);
          });
      } else {
        await Bus.$emit(`sign-${this.appCallBackUrl}`, false);
        await store.dispatch('user/setAppCallBackUrl', null);
        await store.dispatch('user/setCert', null);
      }
    },
    // 문서 이름 변경 : "[기관명] 제목" 으로 변경
    getDocumentTitle(obj) {
      const docTypeObj = this.getCodes.find((o) => o.id === obj.docTypeId);
      return docTypeObj ? docTypeObj.docOrgName : '';
    },
  },
};
