<template>
  <div id="app">
    <router-view />
    <slideout-panel></slideout-panel>
    <transition name="fade">
      <div v-show="isLoadingBar" class="loading_center">
        <div class="loading_layer">
          <div class="ball01"></div>
          <div class="ball02"></div>
          <div class="ball03"></div>
          <div class="ball04"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { http } from '@/lib/axios';
import storage from '@/lib/storage';
import { _url } from '@/lib/url';
import store from '@/store';

export default {
  name: 'app',
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['isLoadingBar', 'refLoadingBarCount']),
  },
  created() {
    // 뒤로가기 key 저장소 초기화
    storage.setItem('backKeyStorage', ['Exit-App']);
  },
  mounted() {
    window.androidBackButtonClick = this.appBackButtonBridge;
    window.appScrtMnsWPCSignCallback = this.appScrtMnsWPCSignCallback;
    window.appBrowserBridge = this.appBrowserBridge;
    if (process.env.NODE_ENV === 'local') {
      const no = '20230828-1';
      const idn = `ci-${no}`; // ci
      const loginId = `id-${no}`; // 통합고객번호
      const userName = `nm-${no}`; // 이름
      const joinYn = 'Y'; // 사설인증서 가입여부
      const loginYn = 'Y'; // 사설인증서 로그인 여부
      const serialNo = `serial-${no}`; // 사설인증서 시리얼
      const userWonp = `pw-${no}`; // 비밀번호
      const id = 'ss'; // 솔루션 idn key
      const eaddr = ''; // 솔루션 공인전자 주소
      const status = id ? 1 : null; // 가입 상태값

      store.dispatch('user/setUser', {
        idn,
        loginId,
        userName,
        joinYn,
        loginYn,
        serialNo,
        userWonp,
        id,
        status,
        eaddr,
      });
      this.routerNamePush(status > 0 ? 'home' : 'intro');
    } else {
      this.appUserInfoBridge();
    }
  },
  methods: {
    async appUserInfoBridge() {
      const path = this.$route.path.split('/');
      if (path.length === 0) {
        this.alertPopup('', '정상적인 접근이 아닙니다.').then(() => {
          this.appExitBridge();
        });
        return;
      }

      const itcsno = path[2];
      const ci = [];
      for (let i = 3; i < path.length; i++) { // 배열 arr의 모든 요소의 인덱스(index)를 출력함.
        ci.push(path[i]);
      }

      const result = await this.appCiCheck({ ciNo: ci.join('/'), itCsNo: itcsno });
      if (result) {
        const params = {
          ci: ci.join('/'),
        };

        http.get(_url.user, { params }).then(async (res) => {
          const status = res.status || -1;
          await store.dispatch('user/setUser', {
            idn: res.ciNo,
            loginId: res.itCsNo,
            userName: res.userNm,
            joinYn: res.pvtCrtfUsgYn,
            loginYn: res.pvtCrtfLginYn,
            serialNo: res.serialNo,
            userWonp: res.userWonp,
            id: res.id,
            eaddr: res.eaddr,
            status,
          });
          await this.routerNamePush(status > 0 ? 'home' : 'intro');
        }).catch(() => {});
      } else {
        this.alertPopup('', '정상적인 접근이 아닙니다.').then(() => {
          this.appExitBridge();
        });
      }
    },
    async appCiCheck(obj) {
      // 로컬서버일 경우 예외 development
      if (process.env.VUE_APP_CA_USE === 'false') {
        return true;
      }
      
      const res = await http
        .post(_url.certVerify, {
          ci: obj.ciNo,
          itcsno: obj.itCsNo,
        }, {
          headers: {
            Authorization: process.env.VUE_APP_CA_TOKEN,
          },
        });

      return res.RESULT_CODE === '0000';
    },
  },
};
</script>
