export const _url = {
  // 진입 화면
  user: `${process.env.VUE_APP_MCC_WAS}/user`, // 회원정보 호출
  exist: `${process.env.VUE_APP_MCM_WAS}/exist`, // 가입여부 조회

  // 회원가입 전
  certVerify: `${process.env.VUE_APP_CA_URL}/wpot/cert/verifyCi`, // 공통 > 사설인증 전자서명 체크
  certVerifyResult: `${process.env.VUE_APP_CA_URL}/wpot/cert/signVerify`, // 공통 > 사설인증 전자서명 체크


  termsAll: `${process.env.VUE_APP_MCC_WAS}/api/terms-all`, // 회원가입요청 > 약관동의
  terms: `${process.env.VUE_APP_MCC_WAS}/api/terms`, // 회원가입요청 > 약관동의
  signup: `${process.env.VUE_APP_MCM_WAS}/signup`, // 회원가입요청 > 솔루션 가입
  tos: `${process.env.VUE_APP_MCC_WAS}/api/tos`, // 회원가입요청 > 약관저장

  // 회원가입 후
  issueToken: `${process.env.VUE_APP_MCC_WAS}/auth/issue-token`, // 메인화면 > 토큰 및 리플레쉬 토큰 생성
  refreshToken: `${process.env.VUE_APP_MCC_WAS}/auth/refresh-token`, // 메인화면 > 토큰 및 리플레쉬 토큰 생성

  // 수신문서
  edocIn: `${process.env.VUE_APP_MCM_WAS}/api/edoc/in`, // 수신문서
  edocInCnt: `${process.env.VUE_APP_MCM_WAS}/api/edoc/in/count`, // 수신문서 전체 수 조회
  edocInResult: `${process.env.VUE_APP_MCM_WAS}/api/edoc/in/result`, // 수신문서 상세조회(id)
  docReport: `${process.env.VUE_APP_MCC_WAS}/api/doc-report`, // 신고하기
  ottIssue: `${process.env.VUE_APP_MCM_WAS}/api/edoc/ott/issue`, // 일회용토큰발급
  certDown: `${process.env.VUE_APP_MCC_WAS}/api/cert`, // 유통증명서 다운로드



  // 문서신청
  notices: `${process.env.VUE_APP_MCC_WAS}/api/notice`, // 공지사항
  docOrg: `${process.env.VUE_APP_MCM_WAS}/api/doc-org`, // 이용기관 목록
  rcvReject: `${process.env.VUE_APP_MCM_WAS}/api/rcv-reject`, // 이용기관 수신거부 목록

  // 메인화면 > 자세히보기 > 유통증명서, 유통증명서 다운로드(id) (mcm)
  cert: `${process.env.VUE_APP_MCM_WAS}/api/cert`,

  // 메인화면 > 자세히보기 > 서비스해지
  adminUsers: `${process.env.VUE_APP_MCM_WAS}/admin/users`,
  usersSuspend: `${process.env.VUE_APP_MCM_WAS}/admin/users/suspend`,
  userDelete: `${process.env.VUE_APP_MCC_WAS}/admin/users/suspend`, // 회원가입요청 > 약관저장

  // 문서유형 목록 조회
  getDocType: `${process.env.VUE_APP_MCM_WAS}/api/doc-type`,
};
